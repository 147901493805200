<template>
  <div class="w-full h-full">
    <Confirmation mode="ess" />
  </div>
</template>

<script>
import Confirmation from "@/components/confirmation";

export default {
  name: 'ConfirmationView',
  components: {
    Confirmation
  }
}
</script>